<template>
  <div class="home">
    <header class="home-header">
      <div class="icon-home"></div>
      <div class="head-search" @click="toSearch">
        <img src="../../assets/image/home/icon-search.png" class="icon-search">
        <span>请输入搜索内容～</span>
      </div>
      <!-- 测试是否推送成功～ -->
      <div class="head-welcome">
        <p class="tit">Hi，同学你好~</p>
        <p class="txt" v-if="state.number == '0'">学习如逆水行舟，不进则退~</p>
        <p class="txt" v-if="state.number == '1'">非淡泊无以明志，非宁静无以致远~</p>
        <p class="txt" v-if="state.number == '2'">学如才识，不日进，则日退~</p>
        <p class="txt" v-if="state.number == '3'">进学致和，行方思远~</p>
      </div>
      <!-- 推荐置顶课程 -->
      <template v-if="state.recommendCourses.length > 0">
        <van-swipe class="my-swipe" indicator-color="#FC6C21" :autoplay="3000">
          <van-swipe-item v-for="(item ,index) in state.recommendCourses" :key="index" @click="toDetail(item)">
            <img src="../../assets/image/home/icon-tuijian.png" class="icon-tuijian">
            <div class="item-lt">
              <img :src="item.thumb" class="img">
            </div>
            <div class="item-rt">
              <div class="title f-owt-two">{{item.title}}</div>
              <div class="rt-bt">
                <template v-if="item.type == 6">
                  <span class="txt">{{item.exam_time[0]}}</span>
                </template>
                <template v-else>
                  <div class="price">
                    <span>¥</span>
                    {{item.price}}
                    <span class="spike" v-if="item.flag == 3"></span>
                  </div>
                  <!-- <span class="txt" v-if="item.limit_num > 0">{{item.buy_num}}/{{item.limit_num}}限购</span>
                  <div class="txt" v-else-if="item.buy_num !== undefined">{{item.buy_num}} 已购</div>
                  <span class="txt" v-else>不限购</span> -->
                </template>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </template>
    </header>
    <section class="tab-type">
      <!-- 课程类型 -->
      <van-tabs class="type" line-width="0">
        <van-tab v-for="(item ,index) in state.typeList" :key="index">
          <template #title>
            <div>
              <button class="tit" :class="{'active':state.type == item.value}" @click="bindType(item.value)">{{item.text}}</button>
            </div>
          </template>
        </van-tab>
      </van-tabs>
      <!-- 网课图书类型展示 -->
      <!-- <div class="items" v-if="state.type == 0 || state.type == 5">
				<img src="../../assets/image/home/icon-more.png" class="icon-more">
				<div class="item">
					<button class="tit" v-for="(item ,index) in state.itemsList" :key="index" :class="{'active':state.item_id == item.value}" @click="bindItem(item.value)">{{item.text}}</button>
				</div>
			</div> -->
      <!-- 网课图书类型展示 -->
      <div class="mianshou" v-if="state.type == 1 || state.type == 5">
        <van-dropdown-menu active-color="#FC6C21">
          <van-dropdown-item v-model="state.item_id" :options="state.network_book_list" :title="state.itemIdTitle" @change="bindItemSelect" />
          <van-dropdown-item v-model="state.subject_id" :options="state.nbSubList" :title="state.subjectTitle" @change="bindSubjectSelect" />
          <van-dropdown-item v-model="state.exam_type" :options="state.examTypeList" :title="state.examTypeTitle" @change="bindExamSelect" />
        </van-dropdown-menu>
      </div>
      <!-- 面授课程类型展示 -->
      <div class="mianshou" v-if="state.type == 2 || state.type == 3 || state.type == 4">
        <van-dropdown-menu active-color="#FC6C21">
          <van-dropdown-item v-model="state.item_id" :options="state.itemsList" :title="state.itemIdTitle" @change="bindItemSelect" />
          <van-dropdown-item v-model="state.exam_type" :options="state.examTypeList" :title="state.examTypeTitle" @change="bindExamSelect" />
        </van-dropdown-menu>
      </div>
      <!-- 模考大赛 -->
      <div class="mianshou" v-if="state.type == 6">
        <van-dropdown-menu active-color="#FC6C21">
          <van-dropdown-item v-model="state.item_id" :options="state.mockAreaList" :title="state.itemIdTitle" @change="bindItemSelect" />
          <van-dropdown-item v-model="state.subject_id" :options="state.subjectList" :title="state.subjectTitle" @change="bindSubjectSelect" />
          <van-dropdown-item v-model="state.exam_type" :options="state.mockTypeList" :title="state.examTypeTitle" @change="bindExamSelect" />
        </van-dropdown-menu>
      </div>
    </section>
    <!-- 课程列表 -->
    <KechList :loading="state.loading" :courseList="state.courseList" @onLoad="onLoad" :finished="state.finished" type="detail" :show="state.show" text="暂未上架课程，请联系课程顾问或客服" linkTel='400-601-9999' :cellType="state.cellType" @mockRegister="bindMockRegister" @toDetailEvent="bindToDetail"></KechList>
    <!-- 导航 -->
    <tabbar></tabbar>
    <!-- 模考大赛地区以及机构弹窗 -->
    <mockPop :show="state.mock.show" :isRegister="state.mock.isRegister" :mockData="state.mock.mockData" :areaSelIdx="state.mock.areaSelIdx" :agencySelIdx="state.mock.agencySelIdx" :callBy="state.mock.callBy" @onSelectArea="bindSelectArea" @onSelectAgency="bindSelectAgency" @onClosePo="bindClosePop" @confirm="bindConfirm"></mockPop>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  onMounted,
  getCurrentInstance,
  computed,
  watch,
  nextTick
} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { commitAddShopId, getStore, setStore } from '../../utils/util'
import tabbar from '../../components/tabbar/index.vue'
import KechList from '../../components/kechList/index.vue'
import mockPop from '../../components/mock/pop.vue'
export default {
  name: 'home',
  components: {
    tabbar,
    KechList,
    mockPop
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const $router = useRouter()
    const $route = useRoute()
    const store = useStore()
    const filter = ref(null)
    const userInfo = getStore('userInfo')
    const MOCK_OPTYPE = {
      TO_REGISTER: 'CLICK_TO_REGISTER',
      POP_CONFIRM: 'CLICK_POP_CONFIRM',
      POP_CLOSE: 'CLICK_POP_CLOSE',
      CURRENT: ''
    }
    const FLAG_STATUS = {
      MOCK_LAST_OP: ''
    }
    const state = reactive({
      recommendCourses: [], //置顶课程列表
      courseList: [], //课程列表
      typeList: [], //课程类型列表
      itemsList: [], //考试项目
      examTypeList: [], //考试类型列表
      mockAreaList: [], // 模考大赛地区
      mockTypeList: [], // 模考科目列表
      subjectList: [], // 考试类型列表
      network_book_list: [], // 网课和图书关联的项目和类型列表
      loading: false, //列表加载
      finished: false, //分页加载完成
      total: 0, //数据总数
      page: 1, //分页
      type: 1, //课程类型
      item_id: 0, //考试项目
      exam_type: 0, //科目类型
      subject_id: 0, // 考试类型
      examTypeTitle: '笔面试',
      itemIdTitle: '考试项目',
      subjectTitle: '考试类型',
      number: '', //随机0-3
      shop_id: 'n', //默认店铺
      show: false,
      cellType: 'other',
      mock: {
        show: false,
        isRegister: 0,
        mockData: {},
        areaSelIdx: -1,
        agencySelIdx: -1,
        callBy: 0 // 0表示人为点击弹窗，1表示报名成功弹窗
      }
    })

    // 统一处理监听属性变化函数，监听state.type/item_id/exam_type/subject_id的变化
    const handleStateDataChange = (dataType) => {
      return (newVal, oldVal) => {
        switch (dataType) {
          case 'type':
            if (newVal == 6) {
              state.examTypeTitle =
                state.exam_type == 0
                  ? '请选择科目'
                  : state.mockTypeList.find(
                      (item) => item.value == state.exam_type
                    )['text']
              state.itemIdTitle =
                state.item_id == 0
                  ? '请选择模考地区'
                  : state.mockAreaList.find(
                      (item) => item.value == state.item_id
                    )['text']
              state.subjectTitle =
                state.subject_id == 0
                  ? '请选择考试类型'
                  : state.subjectList.find(
                      (item) => item.value == state.subject_id
                    )['text']
            } else {
              state.examTypeTitle = '笔面试'
              state.itemIdTitle = '考试项目'
              state.subjectTitle = '考试类型'
            }
            break
          case 'item_id':
            if (state.type != 6) {
              if (newVal == 0) {
                state.itemIdTitle = '考试项目'
              } else {
                state.itemIdTitle = state.itemsList.find(
                  (item) => item.value == newVal
                )['text']
              }
            } else {
              if (newVal == 0 || newVal == 86) {
                state.itemIdTitle = '请选择模考地区'
              } else {
                state.itemIdTitle = state.mockAreaList.find(
                  (item) => item.value == newVal
                )['text']
              }
            }
            break
          case 'exam_type':
            if (state.type != 6) {
              if (newVal == 0) {
                state.examTypeTitle = '笔面试'
              } else {
                state.examTypeTitle = state.examTypeList.find(
                  (item) => item.value == newVal
                )['text']
              }
            } else {
              if (newVal == 0) {
                state.examTypeTitle = '请选择科目'
              } else {
                state.examTypeTitle = state.mockTypeList.find(
                  (item) => item.value == newVal
                )['text']
              }
            }
            break
          case 'subject_id':
            if (state.type == 6) {
              if (newVal == 0) {
                state.subjectTitle = '请选择考试类型'
                state.mockTypeList = [{ text: '全部', value: 0 }]
              } else {
                state.subjectTitle = state.subjectList.find(
                  (item) => item.value == newVal
                )['text']
                state.mockTypeList = state.subjectList.find(
                  (item) => item.value == newVal
                )['child']
                // 当关联科目只有一个分类的话，默认选择改科目
                if (state.mockTypeList.length == 1) {
                  state.exam_type = state.mockTypeList[0].value
                }
              }
            } else if (state.type == 1 || state.type == 5) {
              if (newVal == 0) {
                state.subjectTitle = '考试类型'
              } else {
                state.subjectTitle = state.nbSubList.find(
                  (item) => item.value == newVal
                )['text']
              }
            }
            break
          default:
            return
        }
      }
    }

    // 监听state.type的变化
    watch(() => state.type, handleStateDataChange('type'))
    // 监听state.item_id的变化
    watch(() => state.item_id, handleStateDataChange('item_id'))
    // 监听state.exam_type的变化
    watch(() => state.exam_type, handleStateDataChange('exam_type'))
    // 监听state.subject_id的变化
    watch(() => state.subject_id, handleStateDataChange('subject_id'))

    const mock_area_arr = computed(() => {
      if (state.mock.mockData != '') {
        let res = [],
          _data = state.mock.mockData.mock_area
        for (let key in _data) {
          res.push({
            id: key,
            value: _data[key]
          })
        }
        return res
      }
    })

    onMounted(() => {
      //随机0-3
      state.number = Math.round(Math.random() * 3)

      // 获取shop_id
      if (getStore('shop_id')) {
        state.shop_id = getStore('shop_id')
      }

      // 当前url参数的shop_url
      if ($route.params.shop_id) {
        setStore('shop_id', $route.params.shop_id)
        // store.commit("globalData/addShopId", $route.params.shop_id);//全局shop_id
        commitAddShopId(store, proxy, $route.params.shop_id)
        state.shop_id = $route.params.shop_id
      }

      console.log('店铺ID===>', state.shop_id)
      getdata('init') //获取数据
      getRecommend() //获取筛选数据
    })

    // // 跳转详情页
    // const toDetail = (id) => {
    // 	$router.push({
    // 		name: 'detail',
    // 		params: {
    // 			dis_id: id,
    // 		}
    // 	});
    // }

    // 跳转详情页新神2的课程跳M站
    const toDetail = (item) => {
      if (item.platform == 8) {
        window.location.href = item.mv_url
      } else {
        let _name = 'detail',
          params = {
            dis_id: item.ref_id
          }
        if (item.type == 6) {
          _name = 'mock'
          params = {
            mock_id: item.ref_id
          }
        }
        $router.push({
          name: _name,
          params: params
        })
      }
    }

    // 跳转搜索页
    const toSearch = () => {
      $router.push({
        name: 'search'
      })
    }

    // 课程类型切换
    const bindType = (id) => {
      state.type = id
      state.cellType = id == 6 ? 'mokao' : 'other'
      state.item_id = 0 //考试项目
      state.subject_id = 0 // 考试类型
      state.nbSubList = state.network_book_list[state.item_id].child
      state.courseList = [] //课程列表
      ;(state.loading = true), //列表加载
        (state.finished = false) //分页加载完成
      state.total = 0 //数据总数
      state.page = 1 //分页
      state.exam_type = 0 //考试类型
      getdata() //获取数据
    }

    // 考试项目切换
    const bindItem = (id) => {
      state.item_id = id
      // state.type !== 6 && (state.nbSubList = state.network_book_list[state.item_id].child);
      if (state.type !== 6) {
        let row = state.network_book_list.find((item) => {
          if (item.value == id) {
            return item
          }
        })
        state.nbSubList = row.child
      }
      state.courseList = [] //课程列表
      ;(state.loading = true), //列表加载
        (state.finished = false) //分页加载完成
      state.total = 0 //数据总数
      state.page = 1 //分页
      getdata() //获取数据
    }
    const bindItemSelect = () => {
      bindItem(state.item_id)
    }

    const bindSubject = (id) => {
      state.subject_id = id
      state.courseList = [] //课程列表
      ;(state.loading = true), //列表加载
        (state.finished = false) //分页加载完成
      state.total = 0 //数据总数
      state.page = 1 //分页
      getdata() //获取数据
    }

    const bindSubjectSelect = () => {
      bindSubject(state.subject_id)
    }

    // 考试类型切换
    const bindExam = (id) => {
      state.exam_type = id
      state.courseList = [] //课程列表
      ;(state.loading = true), //列表加载
        (state.finished = false) //分页加载完成
      state.total = 0 //数据总数
      state.page = 1 //分页
      getdata() //获取数据
    }
    const bindExamSelect = () => {
      bindExam(state.exam_type)
    }

    // 筛选重置
    const bindReset = () => {
      state.courseList = [] //课程列表
      ;(state.loading = false), //列表加载
        (state.finished = false) //分页加载完成
      state.total = 0 //数据总数
      state.page = 1 //分页
      state.item_id = 0 //考试项目
      state.exam_type = 0 //考试类型
      filter.value.toggle() //收起下拉框
      getdata() //获取数据
    }

    // 筛选查找
    const bindSearch = () => {
      filter.value.toggle() //收起下拉框
      getdata() //获取数据
    }

    // 上拉加载
    const onLoad = () => {
      if (state.courseList !== '') {
        state.page++
        state.loading = true

        getdata()
        console.log('加载更多～')
      }
    }

    // 获取数据
    const getdata = (_type) => {
      if (state.page == 1) {
        let sh = store.state.globalData.homeScrollTop
        console.log(sh)
        !(sh - 0 > 0) &&
          proxy.$toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: '加载中...'
          })
      }
      let data = ''

      if (state.type == 0 || state.type == 5) {
        data = {
          shop_id: state.shop_id,
          page: state.page,
          type: state.type, //课程类型
          item_id: state.item_id, //考试项目
          subject_id: state.subject_id, // 考试类型
          exam_type: state.exam_type //考试科目
        }
      } else if (state.type == 6) {
        data = {
          shop_id: state.shop_id,
          page: state.page,
          type: state.type, //课程类型
          item_id: 0, //考试项目
          areacode: state.item_id, // 模考地区
          exam_type: state.exam_type, //考试科目
          subject_id: state.subject_id // 考试类型
        }
      } else {
        data = {
          shop_id: state.shop_id,
          page: state.page,
          type: state.type, //课程类型
          item_id: state.item_id, //考试项目
          areacode: state.item_id, // 模考地区
          exam_type: state.exam_type //考试类型
        }
      }

      proxy.$http
        .get('/api/course/list', { params: data })
        .then((res) => {
          console.log('课程列表===>', res.data)
          const body = res.data.data
          proxy.$toast.clear()

          if (res.data.code == '200') {
            state.loading = false
            state.total = body.total

            if (state.courseList == '' && body.data == '') {
              state.show = true
            } else {
              state.show = false
            }

            if (body.data == null || body.data.length === 0) {
              // 加载结束
              state.finished = true
              return
            }

            // 将新数据与老数据进行合并
            state.courseList = state.courseList.concat(body.data)

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (state.courseList.length >= state.total) {
              state.finished = true
            }

            let home = document.querySelector('.home'),
              sh = store.state.globalData.homeScrollTop
            if (_type === 'init' && sh - 0 > 0) {
              nextTick(() => {
                home.scrollTop = sh - 0
                store.commit('globalData/addHomeScrollTop', '')
              })
            }

            highlights()
          } else {
            console.log(res.data.code)
          }
        })
        .catch((error) => console.log(error))
    }

    // 获取置顶课程+筛选内容
    const getRecommend = () => {
      proxy.$http
        .get('/api/course/recommend/' + state.shop_id, {
          params: {
            is_token: false
          }
        })
        .then((res) => {
          console.log('置顶课程+筛选内容===>', res.data)
          const body = res.data.data

          if (res.data.code == '200') {
            state.recommendCourses = body.recommendCourses
            state.itemsList = body.items
            state.typeList = body.types
            state.examTypeList = body.exam_type
            state.mockAreaList = body.area
            state.subjectList = body.mock_type
            state.mockTypeList = body.mock_type[state.subject_id].child || [
              { text: '全部', value: 0 }
            ]
            state.network_book_list = body.network_book
            state.nbSubList = state.network_book_list[state.item_id].child
          } else {
            console.log(res.data.code)
          }
        })
        .catch((error) => console.log(error))
    }

    // 模考大赛报名
    const bindMockRegister = (_mockItem) => {
      if (!userInfo || !userInfo.name) {
        toLogin()
      } else {
        FLAG_STATUS.MOCK_LAST_OP = MOCK_OPTYPE.TO_REGISTER
        state.mock.callBy = 0
        proxy.$libs
          .getMockDetailData(_mockItem.ref_id)
          .then((res) => {
            if (res.data.code == 200) {
              let mockData = res.data.data
              state.mock.mockData = mockData
              state.mock.isRegister = parseInt(state.mock.mockData.is_register)
              // 处理默认选中
              if (
                mockData.is_register != 0 &&
                mockData.mock_area[mockData.is_register]
              ) {
                let _idx = mock_area_arr.value.findIndex((item) => {
                  return mockData.is_register == item.id
                })
                _idx != -1 &&
                  (mockData.subject_id == 8
                    ? (state.mock.agencySelIdx = _idx)
                    : (state.mock.areaSelIdx = _idx))
              }
              state.mock.show = true
            } else {
              proxy.$toast(res.data.message)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }

    /**
     * 跳转登录页
     */
    const toLogin = () => {
      $router.push({
        path: '/login'
      })
    }

    const bindSelectArea = (e) => {
      let target = e.target
      if (
        target.className == 'item-area' &&
        target.dataset &&
        'id' in target.dataset
      ) {
        let _id = target.dataset.id,
          _selIdx = mock_area_arr.value.findIndex((item) => {
            return item.id == _id
          })
        // console.log('_selId:', _selIdx);
        state.mock.areaSelIdx = _selIdx || 0
      }
    }

    const bindSelectAgency = (index) => {
      state.mock.agencySelIdx = index
    }

    const bindConfirm = () => {
      if (
        !(
          userInfo &&
          userInfo !== '' &&
          userInfo.expires_in * 1000 - new Date() > 0
        )
      ) {
        // 判断是否token过期
        return toLogin()
      }
      FLAG_STATUS.MOCK_LAST_OP = MOCK_OPTYPE.POP_CONFIRM
      // 区分国考还是省考
      let selIdx =
          state.mock.mockData.subject_id == 8
            ? state.mock.agencySelIdx
            : state.mock.areaSelIdx,
        user_mock_type = mock_area_arr.value[selIdx].id
      let params = {
        mock_id: state.mock.mockData.id,
        mobile: userInfo.mobile,
        shop_id: state.mock.mockData.shop_id,
        user_mock_type: user_mock_type
      }
      proxy.$libs
        .mockRegister(params)
        .then((res) => {
          console.log('报名结果:', res)
          if (res.data.code == 200) {
            state.mock.isRegister = parseInt(user_mock_type)
            state.mock.mockData.is_register = user_mock_type
            state.mock.mockData.mock_type = res.data.data.mock_type
            state.mock.mockData.mobile_format = res.data.data.mobile_format
            state.mock.callBy = 1
          } else {
            proxy.$toast(res.data.message)
          }
        })
        .catch((err) => {
          console.log('报名失败:', err)
        })
    }

    const bindClosePop = () => {
      state.mock.show = !state.mock.show
      if (FLAG_STATUS.MOCK_LAST_OP === MOCK_OPTYPE.POP_CONFIRM) {
        state.courseList = [] // 清空模考列表，刷新
        getdata()
      }
      FLAG_STATUS.MOCK_LAST_OP = MOCK_OPTYPE.POP_CLOSE
    }

    // 监听课程列表点击前往课程详情页的事件
    const bindToDetail = () => {
      let home = document.querySelector('.home')
      let sh = home.scrollTop
      if (sh - 0 > 0) {
        store.commit('globalData/addHomeScrollTop', sh)
      }
    }

    // 搜索关键字高亮
    const highlights = () => {
      state.courseList = state.courseList.map((item) => {
        if (item.type == 0 || item.type == 1 || item.type == 7) {
          let span = '<span style="padding-left:42px"></span>'
          item.title01 = span + item.title
        } else {
          item.title01 = item.title
        }
        return item
      })
    }

    return {
      state,
      getdata,
      onLoad,
      bindType,
      bindItem,
      bindExam,
      bindSearch,
      bindReset,
      filter,
      highlights,
      bindItemSelect,
      bindExamSelect,
      bindSubjectSelect,
      toDetail,
      getRecommend,
      toSearch,
      bindMockRegister,
      bindSelectArea,
      bindSelectAgency,
      bindConfirm,
      bindClosePop,
      bindToDetail
    }
  }
}
</script>

<style scoped lang="scss" src="./index.scss"></style>
